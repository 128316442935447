var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "14",
        height: "14",
        viewBox: "0 0 14 14",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
      on: {
        click: function ($event) {
          return _vm.$emit("icon-clicked")
        },
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M0.999999 7C0.999999 10.3137 3.68629 13 7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 0.999999 3.68629 0.999999 7Z",
          fill: "#67C23A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M7 4.375L7 9.625",
          stroke: "white",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M4.375 7L9.625 7",
          stroke: "white",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }